import { FiInstagram, FiMail } from "react-icons/fi";

export default function About({ selected }) {
  return (
    <div className={selected ? "page selected about" : "page about"}>
      <div className="wrapper">
        <div className="content title" style={{ paddingBottom: "1rem" }}>
          <span className="title">Om sågen</span>
        </div>
        <div className="content">
          <div>
            <p>
              Öjhults såg - vackert beläget utmed Färgesjön i Lilla Öjhult, vid
              den skarpa kurvan mellan Valdshult och Tranhult (väg 640 Öreryd-
              Skillingaryd) - är ett sågverk med en lång och stolt historik.
              Sedan hösten 2021 håller sågen på att startas upp på nytt i formen
              av Öjhults Såg AB. Målet är att inom de närmsta åren komma i gång
              med inköp av timmer, sågverksamhet, hyvling samt försäljning av
              trävaror. Ta gärna kontakt med oss om du har intresse av att
              arbeta med trävaror i ett småskaligt sågverk och vill vara med på
              vår resa!
            </p>
          </div>
          <div>
            <p style={{ textAlign: "right" }}>
              <i>Carl-Johan Jonsson</i>
            </p>
          </div>
          <br />
        </div>
        <div className="content grid-30-70">
          <div className="contact-info">
            <div className="link">
              <span>
                <FiMail style={{ "vertical-align": "middle" }} />
                <a href="mailto:kontakt@ojhultssag.se">kontakt@ojhultssag.se</a>
              </span>
            </div>
            <div className="link">
              <span>
                <FiInstagram style={{ "vertical-align": "middle" }} />
                <a href="https://www.instagram.com/ojhultssag/">instagram</a>
              </span>
            </div>
            <div className="address">
              <span>Öjhults såg AB</span>
              <span>Lilla Öjhult 6</span>
              <span>33572 Hestra</span>
            </div>
          </div>
          <div className="content map">
            {selected ? (
              <iframe
                width="100%"
                title="This is a unique title"
                height="400"
                id="gmap_canvas"
                src="https://maps.google.com/maps?q=%C3%B6jhults%20s%C3%A5g&t=&z=7&ie=UTF8&iwloc=&output=embed"
                frameBorder="0"
                scrolling="no"
                marginHeight="0"
                marginWidth="0"
              ></iframe>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
