import Overlay from "./modules/overlay";
import React, { useState } from "react";
import Start from "./modules/start";
// import Services from "./modules/services";
import History from "./modules/history";
import About from "./modules/about";

function PageCarousell({ selectedPage }) {
  var leftShift = 0;

  switch (selectedPage) {
    case "history":
      leftShift = "-100vw";
      break;
    case "about":
      leftShift = "-200vw";
      break;
    // case "services":
    //   leftShift = "-300vw";
    //   break;

    default:
      leftShift = "0";
      break;
  }

  return (
    <div
      className="page-carousell"
      style={{ transform: "translate(" + leftShift + ")" }}
    >
      <Start selected={selectedPage === "start"} />
      {/* <Services selected={selectedPage === "services"} /> */}
      <History selected={selectedPage === "history"} />
      <About selected={selectedPage === "about"} />
    </div>
  );
}

function App() {
  const params = new URL(document.location).searchParams;
  const pageQuery = params.get("page");

  const [page, setPage] = useState(pageQuery ?? "start");
  return (
    <div
      className={page === "start" ? "start-selected container" : "container"}
    >
      <main>
        <div className="background-module"></div>
        <div className="background-overlay"></div>

        <PageCarousell selectedPage={page} />

        <Overlay setSelectedPage={(e) => setPage(e)} selectedPage={page} />
      </main>
    </div>
  );
}

export default App;
