export default function History({ selected }) {
  const text_1 = (
    <>
      <p>
        Efter sin militärtjänst i Eksjö på 1 12, år 1931 såg han
        utkomstmöjligheter inom skogen. Tiderna var hårda och arbetslöshet
        tillhörde vardagen. Arvslotten efter hans alltför tidigt avlidna moder,
        utgjordes till största delen av skog på rot. Det fanns också en
        uppgörelse med dödsboet och tillsatt god man att Ivar och bröderna
        själva fick avverka denna skog för att få ut sin arvsdel.
      </p>
      <p>
        Detta blev en tidig start för Ivar som skogshandlare, även före sin
        militärtjänst, han fick härigenom tidigt sina erfarenheter den "hårda
        vägen" i branschen. Av Ivars anteckningar framgår att han ansvarade för
        redovisningen av sin och brödernas tid och mängd av avverkningarna,
        hästkörslorna och försäljningen av virket tillsammans med den av
        kommunen utsedde gode mannen Johan Svenningsson. Detta blev början på
        hans långa skogsbana. Han drev sin trävarufirma med sågverk i 62 år!
      </p>
      <p>
        Avverkningarna vid den tiden skedde ju helt för hand med gäddsåg, bågsåg
        och yxa. Timmer av lämplig dimension skräddes till bjälkar och slipers,
        resten blev efter kvalité och mått-klassat som sågtimmer, massaved och
        brännved. Skrädningsarbetet var mycket ansträngande och krävde ett
        säkert handlag och bra ögonmått med blick för de tänkta linjer som med
        skrädbilans hjälp förvandlade en rund stock till fyrkantig, liksidig och
        rak bjälke eller slipers. Detta var en egenskap som Ivar hade och
        förfinade och han var enligt uppgift en duktig skrädare. Slipers som
        underlag till järnvägsrälsen var en efterfrågad vara. Dock var
        granskningen vad gällde exakta mått och kvalité i övrigt mycket hård och
        anteckningar visar att några av varje leverans regelmässigt kasserades.
      </p>
      <p>
        Stolpar av raka fina furustammar, 7-12 meter långa inköptes av
        Televerket och av el-linjebolagen till telefon- och kraftlinjer. Dessa
        stolpar skulle vara noggrant renbarkade in i träet, vilket skedde i ett
        första steg med barkspade och slutligt med s.k. bandkniv. Den noggranna
        barkningen krävdes för att den efterföljande impregneringen skulle ge
        full effekt. Pitprops, en annan specialitet som de engelska kolgruvorna
        starkt efterfrågade, var rundvirke av bestämd längd som stöttor i
        gruvgångarna.
      </p>
      <p>
        Alla transporter ur skogen till upplag för flottning eller bilhämtning,
        drevs med häst och släpa eller släde, men i brist på snö och tjäle,
        eller vid längre avstånd med järnhjulsvagn. Inmätningen utfördes sedan i
        allmänhet av Hylte Bruks AB, Munksjö AB, Skogsägarföreningen eller annan
        uppköpare och likvider erhölls så småningom på grund av dessa mätlistor.
      </p>
      <p>
        Ivar berättade att några av dessa första virkesaffärers goda resultat
        fick honom att fortsätta på vägen som egenföretagare. En väg som han
        sedan följde i hela sitt liv. Detta passade Ivar, han ville själv ha
        kommandot över skeendet och sitt liv, och inte minst sina affärer.
      </p>
    </>
  );

  const text_2 = (
    <>
      <div className="title-3">Fortsättning på Ivars trävaruhandel</div>
      <p>
        År 1935 startade Ivar Nilsson och Sigge Talliden och Henning Josefsson
        en sågverksrörelse som till en början var en ambulerande såg som drevs
        med råoljemotor. Naturligtvis ett mycket effektivare sätt att frambringa
        plank, bjälkar och bräder än med skrädbilan. Ambulerandet innebar att
        sågverket flyttades från gård till gård, där behov av sågat virke till
        av hus eller ladugårdar fanns. Ivars anteckningar omtalar platser under
        åren 1935-1940 där sågen producerat virke under längre eller kortare
        tid. Ur denna kavalkad av gårdar och platser kan vi läsa; ”Ulvestorp 4
        dagar, Svinhult 9 dagar, Trollsebo 10 dagar, Hindebo 5 dagar, Stackebo
        10 dagar, Unnaryd 11 dagar, Öreryd 23 dagar, S Valdshult 27 dagar,
        Hjulvhult 4 dagar", osv.{" "}
      </p>
      <p>
        Detta var verkligen en ambulerande verksamhet och den förklaras av den
        tidens svårigheter att transportera virke längre sträckor. Förutom genom
        flottning förekom knappast längre virkestransporter. Lastbilar var då
        relativt ovanliga, alltså enklare då att sätta upp sågen där timret
        fanns och där det färdiga virket sedan skulle användas. I krigets skugga
        in på 1940-talet blev oljebristen mycket svår och råoljan ransonerades
        varför verksamheten avvecklades. Ivar fortsatte emellertid att köpa
        skog, avverka och såga hos andra sågverk bl.a. Bröderna Grens som drevs
        med ångmaskin. Mellan inkallelserna till militärtjänst, försörjde han
        sig på detta samt ett litet jordbruk åren mellan 1942-1945.
      </p>
      <p>
        Ett sågbolag bildades av Ivar Nilsson, Sigge Talliden, Bernt Svensson
        och Gustav Laurinsson 1946 och beslut togs om att bygga sågverket
        stationärt på dess nuvarande plats vid Färgesjöns östra strand.
        Sågbolaget förändrades under 1950-talet så att Sigge Tallidens del
        övertogs av Albert Johansson och Bernts del inlöstes av Ivar.{" "}
      </p>
      <p>
        Denna konstellation bestod fram till avvecklingen 1993. Bolaget ägde såg
        och byggnader medan skogs- och trävaruhandeln drevs av Ivar Nilsson
        ensam. Han fortsatte alltså fram till 1993, vid 83 års ålder, då han
        efter mer än 60 år som egenföretagare i skogsbranschen överlät sågverket
        till en brorson. Denne har senare förändrat inriktning på verksamheten
        till legohyvling.
      </p>
    </>
  );

  const text_3 = (
    <>
      <div className="title-3">Upplevelser på sågbacken</div>
      <p>
        Som son till Ivar vill jag gärna förmedla några minnesbilder från
        verksamheten vid Öjhults såg. Från mars månad, då tjälen i virket gett
        med sig, och fram till augusti var sågverket igång med mindre avbrott
        för vårbruk, hö- och sädesskörd. De flesta som arbetade vid sågverket
        var ju bönder och behövde dessa avbrott för att sköta sina gårdar. Även
        Ivar hade ju den delen att ta hänsyn till.
      </p>
      <p>
        En stor del av min tid efter skolan och på loven tillbringade jag på
        sågen som frivillig, men oftast kommenderad, att hjälpa till med olika
        sysslor som att barka "okant”, slipers och virket som producerades vid
        Öjhults såg spreds över Världen, men mest i närområdet. En stor kund
        Rosendala Trä i Huskvarna, senare Werners i Jönköping. Möbelfabriker i
        Vaggeryd och Skillingaryd var också mycket stora kunder. Särskilt
        Vaggeryds Möbelfabrik med Folke Carlsson var en ofta sedd och välkommen
        kund hos Ivar.
      </p>
      <p>
        Export också, då uppköpare från Herman Nilsson i Berga, Erik Johansson i
        Värnamo (Eriwood), Elof Hansson i Göteborg m.fl. köpte upp och samlade
        ihop till en båtlast. Detta virke kördes till hamn i Halmstad,
        Oskarshamn, Göteborg eller till järnväg i Hestra vidare befordran ut i
        världen. England, Tyskland och även arabvärlden var mottagare av virke
        från Öjhults såg.
      </p>
    </>
  );

  const text_4 = (
    <>
      <div className="title-3">Transporter</div>
      <p>
        Första lastbilsturen jag minns var med Enok, Ingvar Sandahl och min far
        till Bransmo järnvägsstation med sågat virke för lastning på järnväg.
      </p>
      <p>
        Att komma ut och se något mer än åkrarna runt huset och sågbacken
        lockade mig mycket och jag fick bl.a. åka med Ingvar Sandahl o Herbert
        på Åbygget för att hämta stock till pappas sågverk, detta var en riktig
        höjdare tyckte jag även om den resan bara gick till skogen vid Valebo
        där Ingvar o Herbert med ett släpspel lastade stock på den i mina ögon
        stora Volvo bensinlastbilen. Det fanns ingen tipp på denna lastbil så
        vid sågen släpptes fällstakarna så att ungefär hälften av lasset rullade
        av självt och resten fick man baxa av för hand.
      </p>
      <p>
        Vid denna tid köpte far en lastbil till sågen för att i första hand
        klara leverans av svallar till Gnosjö där en marknad öppnats tack vare
        min farbror David. Denna gröna Volvo med reg nr F2171 köpte far i
        Jönköping den 20 mars 1954 hos Nyströms bil, för 2 800 kr. En begagnad
        grönsaksbil som någon odlare i Gisebo förmodligen haft för transporterna
        till Västra Torget i "stan". En del av likviden utgjordes av virke som
        enligt kontraktet skulle vara spåntat golvträ 2-300 kbf i längder minst
        13 fot, flakvirke säkerligen. Denna lastbil hade inte heller någon tipp
        vilket medförde handlastning och lossning.
      </p>
      <p>
        Ett minne av "svallatransport" till Gnosjö där jag fick följa med min
        farbror David som körde denna bil tidvis och jag minns detta som igår.
        Att komma till Gnosjö! Ett stort lass hade vi och bilen hade väl en
        60-70 hästkrafter och David växlade i "Åalia" och missade nerväxlingen
        så han fick börja på ”krypen”=1 an, det var en osynkad låda så det
        gällde att ha rätt varvtal, vi stretade uppför "lia" och David körde med
        tungan ute, nästan som Janne Schafer.{" "}
      </p>
      <p>
        Denna lastbil kan man säga blev inledningen till vad som nu är det
        blomstrande Axelssons Åkeri, numera lett av Lennart med stor framgång.
        Karl-Åke Axelsson började köra denna lilla lastbil efter inköpet men han
        övertalade så småningom min far att större bil med tipp behövdes, vilket
        min far säkert också insåg, varför en Volvo Viking inköptes 1956.
      </p>
      <p>
        Far ville då att Karl-Åke skulle vara med i det så kallade bilbolaget
        vilket han gjorde, av vissa praktiska skäl vad gällde tillstånd m.m.
        gick Bröderna Andersson i Sandsebo in som delägare med en mindre post.
        Tillståndsfrågan var på den tiden mycket allvarlig och konkurrenter
        anmälde bolaget för olaga transporter vilket blev tingssak. Min far och
        Karl-Åke gick nästan fria med symboliska böter efter lite rundor med
        advokat inkopplad.
      </p>
    </>
  );
  const text_5 = (
    <>
      <div className="title-3">Utveckling</div>
      <p>
        Sågverket moderniserades efter hand och flaktrucken inköpt 1960 ersattes
        med en riktig gaffeltruck som inköptes 1965 och arbetet blev enklare och
        effektivare. Det sågade virket lades i paket på rälsvagnar som rullades
        fram och tömdes enkelt med gaffeltrucken. Ett riskabelt arbetsmoment vid
        stapling hade därmed försvunnit. All hantering blev så mycket enklare,
        mätning av virket, paketläggning och bandning kunde ske på marken. Inte
        minst lastningen på bil var nu en snabb affär på en halvtimme för bil
        och släp som vid handlastning tagit omkring 3 timmar.
      </p>
      <p>
        Förändrade metoder, nya krav på annat sortiment och dimensioner mötte
        Ivar med vilja att hänga med i utvecklingen, han intresserade sig mycket
        för skogsbruket och förändringar där. Han hade synpunkter på
        stordriftens nackdelar i skogen, med skogsskador som följd. Han hävdade
        ett genuint intresse för hur skogen med småskaligare skötsel mår bättre
        och därmed ger högre avkastning. Han var inte teknikfientlig och hyllade
        inte återgång till gamla tiders slit med häst och släde, även om hästar
        var hans favoritdjur. Även i sågverksbranschen följde han med i den
        utveckling som under 1970- och 1980-talen gick i ett rasande tempo.
      </p>
      <p>
        Trävarufirman gick med full fart under slutet 1960-talet och in på
        1970-talet. Vissa år var konjunkturen i nedförsbacke men vände som regel
        upp igen. Ivar kände till dessa cykler och oroades inte alltför mycket.
        Han körde sitt företag vidare som han gjort så länge.
      </p>
      <p>
        Ivar som nu närmade sig pensionsåldern visade inga tecken på att
        tröttna. Han trivdes med sin trävaruaffär och tänkte bara köra vidare
        med sågverket. Vid denna tid drev jag eget företag med bensinstation och
        hade inte rätta intresset för trä och skog för att gå in i rörelsen.
        Ivar beklagade detta, men förstod ändå. Han mindes sin egen start som
        skedde i en oförsonligare stämning stämning och han ville inte ha dålig
        relation till sina barn. Ivar körde sitt, om än i mindre skala mot
        slutet, fram till 1993, då trävarurörelsen avvecklades och sågverket
        överläts till en brorson, viket nämnts ovan.
      </p>
      <p>
        Många trotjänare hjälpte Ivar vid sågen under alla dessa år kortare
        eller längre perioder. Några som varit ofta förekommande medarbetare:
        Bertil strand, Tobias Hägg, Karl-Åke Axelsson, Albert, Åke och John
        Johansson, Gustav Laurinsson, Börje och Erik Jonsson, Olof Svensson,
        Våge Svensson, Gösta Pettersson m.fl. profiler som jag, Sven, minns med
        glädje. Arbetskamrater som jag då fick lära mig mycket av och arbeta
        tillsammans med. Några är i livet och jag har förmånen att sporadiskt
        träffa dem.
      </p>
      <p>
        Upptecknat ur minnet och ur anteckningar efterlämnade av Ivar Nilsson.
        Han hade god ordning på sin och bolagens bokföring. Skrev dagbok
        summariskt från 1940-talet och framåt, vilket gör att det varit
        någorlunda lätt att följa samt att finna årtal och uppgifter för denna
        Öjhult sågverks historia. Det känns som att Ivar ville att detta skulle
        berättas för framtida läsare.{" "}
      </p>
    </>
  );

  const text_new_history = (
    <>
      <div className="title-2">Historia 1993-2019</div>
      <p>
        Gunnar Danielsson tog vid 1993 och under egen firma omvandlades
        sågverket till ett legohyvleri. Med mycken klurighet uppfördes två nya
        plåthallar på 700 respektive 250 kvadratmeter där det installerades två
        hyvlar från Waco Jonsered. Det byggdes även en spånlada med automatisk
        uppsugning av kutterspån från hyvlarna samt automatik för bearbetning av
        virkespaket och brädmatning, vilket möjliggjorde soloarbete.{" "}
      </p>
      <p>
        Fordonsparken uppdaterades genom åren med olika hjullastare och
        dieseltruckar. Gunnar bodde i närheten av sågen och det var inte
        ovanligt att förbipasserande hörde hyvlarna långt in på kvällen.
        Sommartid anlades brygga vid Färgesjön för dopp i sjön mellan
        arbetspassen. Gunnar fixade det mesta på egen hand och var verksam fram
        till sitt frånfälle 2019.{" "}
      </p>
    </>
  );

  return (
    <div className={selected ? "page selected history" : "page history"}>
      <div className="wrapper">
        <div className="content">
          <span className="title">Vår historia</span>
        </div>
        <div className="content">{text_new_history}</div>
        <div className="content">
          <div className="title-2">1931-1993</div>
          <p className="italic">
            Författat av Sven Nilsson och publicerat i ”Dörren till Valdshults
            socken”, utgiven av Valdshults hembygdsförening 2011 Sågverkets
            historia är genuint förknippad med grundaren av sågen, Ivar Nilsson
            (1910-2006), som under mer än ett halvsekel drev detta.
          </p>
          {text_1}
        </div>
        <div className="content">{text_2}</div>
        <div className="content">{text_3}</div>
        <div className="content">{text_4}</div>
        <div className="content">{text_5}</div>
      </div>
    </div>
  );
}
