export default function Start({ selected }) {
  return (
    <div className={selected ? "page selected start" : "page start"}>
      {/* <img
        alt="forrest"
        src="images/spruce_forrest.jpg"
        className="background"
      /> */}
      <h1 className="start-title">
        Välkommen till<br></br>Öjhults såg
      </h1>
    </div>
  );
}
