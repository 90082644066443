import React, { useState } from "react";
import { GoThreeBars } from "react-icons/go";
import { HiArrowRight, HiArrowLeft } from "react-icons/hi";

function setUrlSearchParam(pageName) {
  if ("URLSearchParams" in window) {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("page", pageName);
    const newRelativePathQuery =
      window.location.pathname + "?" + searchParams.toString();
    window.history.pushState(null, "", newRelativePathQuery);
  }
}

export default function Overlay({ setSelectedPage, selectedPage }) {
  const pageList = ["start", "history", "about"];

  const [isOpened, setIsOpened] = useState(false);
  var Link = (title, page) => (
    <span className="link">
      <a
        onClick={() => {
          setUrlSearchParam(page);
          setSelectedPage(page);
          setIsOpened(false);
        }}
      >
        {title}
      </a>
    </span>
  );

  var GoLink = () => {
    var pageIndex = pageList.indexOf(selectedPage);
    var page;
    var lastIndex = false;
    if (pageIndex === -1 || pageIndex === pageList.length - 1) {
      page = "start";
      lastIndex = true;
    } else page = pageList[pageIndex + 1];

    return (
      <a
        onClick={() => {
          setUrlSearchParam(page);
          setSelectedPage(page);
        }}
        className="go-link"
      >
        {lastIndex ? (
          <>
            <HiArrowLeft /> Till start
          </>
        ) : (
          <>
            Läs mer <HiArrowRight />
          </>
        )}
      </a>
    );
  };

  return (
    <>
      <GoLink />
      <div className="floater">
        <div
          className="content"
          style={{ verticalAlign: "top" }}
          onClick={() => {
            const page = "start";
            setUrlSearchParam(page);
            setSelectedPage(page);
            setIsOpened(false);
          }}
        >
          <img className="logo" src="images/_os_1x_txt_white.svg" alt="" />
          {/* <span className="title">Öjhults Såg AB</span> */}
        </div>
        <div className={isOpened ? "menu small-show" : "menu"}>
          {/* {Link("Tjänster", "services")} */}
          {Link("Vår historia", "history")}
          {Link("Om sågen", "about")}
          <span className="open-button" onClick={() => setIsOpened(!isOpened)}>
            <GoThreeBars />
          </span>
        </div>
        <footer>
          <small>Öjhults Såg AB &copy; 2022</small>
        </footer>
      </div>
    </>
  );
}
